<template>
  <header class="header">
    <div
      :class="[
        'header__main',
        {
          'header__main--login':
            $route.path.search(/login/) === 1 ||
            $route.path.search(/verify-account/) === 1 ||
            $route.path.search(/password-reset/) === 1 ||
            !user,
        },
      ]"
    >
      <div
        :class="[
          'container',
          'px-md-0',
          {
            'container--login':
              $route.path.search(/login/) === 1 ||
              $route.path.search(/verify-account/) === 1 ||
              $route.path.search(/password-reset/) === 1 ||
              !user,
          },
        ]"
      >
        <div class="header__main__bg">
          <div
            :class="[
              'header__main__logo',
              {
                'header__main__logo--login':
                  $route.path.search(/login/) === 1 ||
                  $route.path.search(/verify-account/) === 1 ||
                  $route.path.search(/password-reset/) === 1 ||
                  !user,
              },
            ]"
          >
            <router-link
              v-if="$route.path.search(/admin/) !== -1"
              to="/admin"
              exact
            >
              <img
                class="header-logo__zinpro"
                src="@/assets/img/FirstStep-RGB-No-Line.png"
                :alt="'ZinPro FirstStep Home' | translate"
              />
              <!-- <img
                class="header-logo__firststep"
                src="@/assets/img/first-step-txtOnly_Rev.png"
                :alt="'ZinPro FirstStep Home' | translate"
              /> -->
            </router-link>
            <router-link v-else to="/" exact>
              <img
                class="header-logo__zinpro"
                src="@/assets/img/FirstStep-RGB-No-Line.png"
                :alt="'ZinPro FirstStep Home' | translate"
              />
              <!-- <img
                class="header-logo__firststep"
                src="@/assets/img/first-step-txtOnly_Rev.png"
                :alt="'ZinPro FirstStep Home' | translate"
              /> -->
            </router-link>
          </div>
        </div>
        <div class="header__main__main-nav">
          <div class="top-nav">
            <ul class="top-nav__left" v-if="group">
              <li class="menu__item">
                <router-link
                  :to="
                    '/users/' +
                      $route.params.userId +
                      '/evaluations/' +
                      evaluation._id
                  "
                >
                  {{ evaluation.name }}
                </router-link>
              </li>
              <li class="menu__item">
                <router-link
                  :to="
                    '/users/' +
                      $route.params.userId +
                      '/evaluations/' +
                      evaluation._id +
                      '/groups/'
                  "
                  v-if="$route.path.search(/assessors/) !== -1"
                >
                  {{ group.name }}
                </router-link>
              </li>
            </ul>
            <ul class="top-nav__right">
              <li class="menu__item">
                <a
                  id="localeDropdown"
                  class="dropdown-toggle"
                  data-toggle="dropdown"
                >
                  {{ 'Language' | translate }}
                </a>
                <div
                  class="dropdown-menu dropdown-menu--language collapse"
                  aria-labelledby="localeDropdown"
                >
                  <a
                    href="javascript:;"
                    class="nav-link d-block p-2"
                    data-toggle="dropdown"
                    @click="setUserLocale('en')"
                    >{{ 'English' | translate }}</a
                  >
                  <a
                    v-for="language in languageList"
                    :key="language.code"
                    href="javascript:;"
                    class="nav-link d-block p-2"
                    data-toggle="dropdown"
                    @click="setUserLocale(language.code)"
                    >{{ language.title | translate }}</a
                  >
                </div>
              </li>
              <li v-if="!isUserLoggedIn" class="menu__item">
                <router-link to="/login">{{ 'Login' | translate }}</router-link>
              </li>
              <li v-if="isUserLoggedIn" class="menu__item">
                <router-link
                  v-if="$route.path.search(/admin/) === 1"
                  to="/admin/profile"
                  >{{ 'My Profile' | translate }}</router-link
                >
                <router-link v-else to="/profile">{{
                  'My Profile' | translate
                }}</router-link>
              </li>
              <li v-if="isUserLoggedIn" class="menu__item">
                <router-link  class="pr-0" to="/logout">{{
                  'Log Out' | translate
                }}</router-link>
              </li>
            </ul>
          </div>
          <template v-if="isUserLoggedIn">
            <div class="right-menu">
              <ul class="navbar-nav">
                <li class="nav-item dropdown dropdown--main">
                  <a
                    class="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                    href="#"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div class="dropdown-toggle__bar"></div>
                    <div class="dropdown-toggle__bar"></div>
                    <div class="dropdown-toggle__bar"></div>
                  </a>
                  <div
                    v-if="$route.path.search(/admin/) === -1"
                    class="dropdown-menu dropdown-menu--main dropdown-menu-right"
                  >
                    <router-link to="/" class="nav-link" exact>
                      {{ 'Home' | translate }}
                    </router-link>
                    <router-link to="/evaluations" class="nav-link" exact>
                      {{ 'Evaluations' | translate }}
                    </router-link>
                    <HeaderBySectionDropdown
                      :group="group"
                      :evaluation="evaluation"
                    ></HeaderBySectionDropdown>
                    <router-link
                      v-if="user && user.role !== 'data_entry'"
                      to="/reports"
                      class="nav-link"
                      exact
                    >
                      {{ 'Reports' | translate }}
                    </router-link>
                    <a
                      :href="$t('https://library.zinprofirststep.com/')"
                      @click="$goToLameness('/', $event)"
                      :class="['nav-link', { disabled: !isOnline }]"
                      target="_blank"
                    >
                      {{ 'Resources' | translate }}
                    </a>
                    <router-link
                      v-if="
                        user &&
                          user.role !== 'data_entry' &&
                          user.role !== 'external'
                      "
                      :to="isOnline ? '/admin' : ''"
                      :class="[
                        'nav-link',
                        'border-none-md',
                        'd-lg-none',
                        { disabled: !isOnline },
                      ]"
                      exact
                    >
                      {{ 'Admin' | translate }}
                    </router-link>
                    <a
                      class="nav-link border-none-md d-lg-none"
                      href="#languageDropdown"
                      data-toggle="collapse"
                      aria-expanded="false"
                    >
                      {{ 'Language' | translate }}
                      <i
                        class="fa fa-angle-down fa-angle-down--font-lg open-dropdown"
                        aria-hidden="true"
                      ></i>
                      <i
                        class="fa fa-angle-up fa-angle-up--font-lg open-dropdown"
                        aria-hidden="true"
                      ></i>
                    </a>
                    <ul class="collapse d-lg-none" id="languageDropdown">
                      <li
                        class="dropdown-item dropdown-item--second pt-0 pr-0 pb-0 pl-3"
                      >
                        <a
                          href="javascript:;"
                          class="nav-link d-block"
                          data-toggle="dropdown"
                          @click="setUserLocale('en')"
                          >{{ 'English' | translate }}</a
                        >
                      </li>
                      <li
                        v-for="language in languageList"
                        :key="language.code"
                        class="dropdown-item dropdown-item--second pt-0 pr-0 pb-0 pl-3"
                      >
                        <a
                          href="javascript:;"
                          class="nav-link d-block"
                          data-toggle="dropdown"
                          @click="setUserLocale(language.code)"
                          >{{ language.title | translate }}</a
                        >
                      </li>
                    </ul>
                    <router-link
                      to="/my-photos"
                      :class="[
                        'nav-link',
                        {
                          'border-none-md':
                            user &&
                            (user.role === 'data_entry' ||
                              user.role === 'external'),
                        },
                      ]"
                      exact
                    >
                      {{ 'My Photos' | translate }}
                    </router-link>
                    <router-link
                      :to="isOnline ? '/admin' : ''"
                      v-if="
                        user &&
                          user &&
                          user.role !== 'data_entry' &&
                          user.role !== 'external'
                      "
                      :class="[
                        'nav-link',
                        'd-none',
                        'd-lg-block',
                        { disabled: !isOnline },
                      ]"
                      exact
                    >
                      {{ 'Admin' | translate }}
                    </router-link>
                    <!--                  <router-link v-if="user" class="nav-link border-none-md" to="/reports">-->
                    <!--                    {{ 'Trace Mineral Calculator' | translate }}-->
                    <!--                  </router-link>-->
                    <router-link to="/profile" class="nav-link d-lg-none" exact>
                      {{ 'My Profile' | translate }}
                    </router-link>
                    <router-link to="/logout" class="nav-link d-lg-none" exact>
                      {{ 'Log Out' | translate }}
                    </router-link>
                  </div>
                  <div v-else class="dropdown-menu dropdown-menu--main">
                    <router-link to="/" class="nav-link" exact>
                      {{ 'Home' | translate }}
                    </router-link>
                    <router-link
                      to="/admin/customer-management"
                      class="nav-link"
                      exact
                    >
                      {{ 'Customers' | translate }}
                    </router-link>
                    <router-link
                      to="/admin/company-management"
                      class="nav-link"
                      exact
                    >
                      {{ 'Companies' | translate }}
                    </router-link>
                  </div>
                </li>
              </ul>
            </div>
          </template>
          <template v-else>
            <div class="right-menu d-lg-none">
              <ul class="navbar-nav">
                <li class="nav-item dropdown dropdown--main">
                  <a
                    class="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                    href="#"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div class="dropdown-toggle__bar"></div>
                    <div class="dropdown-toggle__bar"></div>
                    <div class="dropdown-toggle__bar"></div>
                  </a>
                  <div
                    v-if="$route.path.search(/admin/) === -1"
                    class="dropdown-menu dropdown-menu--main dropdown-menu-right"
                  >
                    <a
                      class="nav-link border-none-md d-lg-none"
                      href="#languageDropdown"
                      data-toggle="collapse"
                      aria-expanded="false"
                    >
                      {{ 'Language' | translate }}
                      <i
                        class="fa fa-angle-down fa-angle-down--font-lg open-dropdown"
                        aria-hidden="true"
                      ></i>
                      <i
                        class="fa fa-angle-up fa-angle-up--font-lg open-dropdown"
                        aria-hidden="true"
                      ></i>
                    </a>
                    <ul class="collapse d-lg-none" id="languageDropdown">
                      <li
                        class="dropdown-item dropdown-item--second pt-0 pr-0 pb-0 pl-3"
                      >
                        <a
                          href="javascript:;"
                          class="nav-link d-block"
                          data-toggle="dropdown"
                          @click="setUserLocale('en')"
                          >{{ 'English' | translate }}</a
                        >
                      </li>
                      <li
                        v-for="language in languageList"
                        :key="language.code"
                        class="dropdown-item dropdown-item--second pt-0 pr-0 pb-0 pl-3"
                      >
                        <a
                          href="javascript:;"
                          class="nav-link d-block"
                          data-toggle="dropdown"
                          @click="setUserLocale(language.code)"
                          >{{ language.title | translate }}</a
                        >
                      </li>
                    </ul>
                    <router-link to="/login" class="nav-link d-lg-none" exact>{{
                      'Login' | translate
                    }}</router-link>
                  </div>
                </li>
              </ul>
            </div>
          </template>
          <div class="left-menu-mobile" v-if="group">
            <ul class="left-menu__list">
              <li class="left-menu__list__menu-item dropdown">
                <HeaderBySectionDropdown
                  :group="group"
                  :evaluation="evaluation"
                ></HeaderBySectionDropdown>
              </li>
            </ul>
          </div>
          <div class="left-menu" v-if="group">
            <ul class="left-menu__list">
              <li class="left-menu__list__menu-item dropdown dropdown--hygiene">
                <a
                  class="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  >{{ 'By Assessor' | translate }}</a
                >
                <ul
                  class="dropdown-menu dropdown-menu--hygiene multi-level"
                  role="menu"
                  aria-labelledby="dropdownMenu"
                  id="headerAccordions"
                  data-children=".dropdown-item"
                >
                  <div
                    v-for="(assessorType, index) in evaluation.order"
                    v-if="
                      getFirstGroupByAssessorType(
                        assessorType,
                        evaluation.groups
                      )
                    "
                    :key="index"
                    class="border-bottom-white"
                  >
                    <li class="dropdown-item">
                      <router-link
                        @click="forceUpdate()"
                        :to="
                          '/users/' +
                            $route.params.userId +
                            '/evaluations/' +
                            evaluation._id +
                            '/groups/' +
                            getFirstGroupByAssessorType(
                              assessorType,
                              evaluation.groups
                            ).id +
                            '/assessors/' +
                            assessorType +
                            '/?assessor_nav=assessor'
                        "
                        class="dropdown-item__link"
                      >
                        {{
                          $store.getters.getAssessorTypeById(assessorType).name
                            | translate
                        }}
                      </router-link>
                      <a
                        class="fa fa-angle-down fa-angle-down--font-lg open-dropdown"
                        @click="openDropdown($event, index)"
                        aria-expanded="false"
                      ></a>
                    </li>
                    <div class="collapse" :id="['headerAccordion' + index]">
                      <li
                        v-for="(groupObj, g_index) in getGroupsByAssessorType(
                          assessorType,
                          evaluation.groups
                        )"
                        class="dropdown-item dropdown-item--second"
                        :key="g_index"
                        v-if="groupObj !== undefined"
                      >
                        <status
                          :assessor="getAssessor(assessorType, groupObj)"
                          :border="'grey'"
                        />
                        <router-link
                          @click="forceUpdate()"
                          :to="
                            '/users/' +
                              $route.params.userId +
                              '/evaluations/' +
                              evaluation._id +
                              '/groups/' +
                              groupObj.id +
                              '/assessors/' +
                              assessorType +
                              '/?assessor_nav=assessor'
                          "
                        >
                          {{ groupObj.name }}
                        </router-link>
                      </li>
                    </div>
                  </div>
                  <li class="dropdown-item border-bottom-white">
                    <router-link
                      :to="
                        '/users/' +
                          $route.params.userId +
                          '/evaluations/' +
                          evaluation._id +
                          '/assessor-summary'
                      "
                      class="link-white"
                      exact
                    >
                      {{ 'Assessor Summary' | translate }}
                    </router-link>
                  </li>
                  <li class="dropdown-item">
                    <router-link
                      @click="forceUpdate()"
                      :to="
                        '/users/' +
                          $route.params.userId +
                          '/evaluations/' +
                          evaluation._id +
                          '/order/'
                      "
                      class="link-white"
                      exact
                    >
                      {{ 'Edit Assessor Order' | translate }}
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="left-menu__list__menu-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  >{{ 'By Group' | translate }}</a
                >
                <ul
                  class="dropdown-menu dropdown-menu--hygiene multi-level"
                  role="menu"
                  aria-labelledby="dropdownMenu"
                >
                  <div
                    v-for="(group, index) in evaluation.groups"
                    :key="index"
                    class="border-bottom-white"
                  >
                    <li class="dropdown-item">
                      <router-link
                        @click="forceUpdate()"
                        :to="
                          '/users/' +
                            $route.params.userId +
                            '/evaluations/' +
                            evaluation._id +
                            '/groups/' +
                            group.id +
                            '/assessors/' +
                            group.assessor_list[0] +
                            '/?assessor_nav=group'
                        "
                        class="dropdown-item__link"
                      >
                        {{ group.name }}
                      </router-link>
                      <a
                        class="fa fa-angle-down fa-angle-down--font-lg open-dropdown"
                        @click="openDropdown($event, 'h' + index)"
                      ></a>
                    </li>
                    <div class="collapse" :id="'headerAccordion' + 'h' + index">
                      <li
                        v-for="(assessorType, a_index) in group.assessor_list"
                        class="dropdown-item dropdown-item--second"
                        :key="a_index"
                      >
                        <status
                          :assessor="getAssessor(assessorType, group)"
                          :border="'grey'"
                        />
                        <router-link
                          @click="forceUpdate()"
                          :to="
                            '/users/' +
                              $route.params.userId +
                              '/evaluations/' +
                              evaluation._id +
                              '/groups/' +
                              group.id +
                              '/assessors/' +
                              assessorType +
                              '/?assessor_nav=group'
                          "
                        >
                          {{
                            $store.getters.getAssessorTypeById(assessorType)
                              .name | translate
                          }}
                        </router-link>
                      </li>
                    </div>
                  </div>
                  <li class="dropdown-item">
                    <router-link
                      :to="
                        '/users/' +
                          $route.params.userId +
                          '/evaluations/' +
                          evaluation._id +
                          '/groups'
                      "
                      class="dropdown-item__link"
                    >
                      {{ 'Add/Edit Groups' | translate }}
                    </router-link>
                  </li>
                </ul>
              </li>
              <li
                v-if="user && user.role !== 'data_entry'"
                class="left-menu__list__menu-item"
              >
                <router-link class="nav-link dropdown-toggle" to="/reports">
                  {{ 'Reports' | translate }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Status from '@/components/evaluations/status.vue';
import HeaderBySectionDropdown from '@/components/common/HeaderBySectionDropdown';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  GET_EVALUATION_BY_ID,
  GET_EVALUATION_BY_ID_GETTER,
  GET_UPDATE_ME,
  SET_UPDATE_ME,
} from '../store/types';
var $ = (window.jQuery = require('jquery'));
window.Popper = require('popper.js').default;
require('bootstrap');

// used to stop the dropdown menu from closing when clicking inside
$(document).on('click', '.dropdown-menu', function(e) {
  e.stopPropagation();
});

export default {
  components: {
    Status,
    HeaderBySectionDropdown,
  },
  data() {
    return {
      id: this.$route.params ? this.$route.params.id : null,
      group: null,
      languageList: [],
      evaluationId: this.$route.params.evaluationId,
      groupId: this.$route.params.groupId,
      // evaluation: {},
    };
  },
  // TODO:
  watch: {
    '$store.state.languageList': {
      handler(list) {
        this.languageList = list.filter(language => language.metadata);
      },
      immediate: true,
    },
  },

  computed: {
    ...mapState({
      user: state => state.users.user || {},
      evaluation: state => state.evaluations.evaluation || {},
    }),
    ...mapGetters(['isOnline']),
    ...mapGetters('evaluations', [GET_EVALUATION_BY_ID_GETTER]),

    isUserLoggedIn() {
      const tokenNotExpired =
        new Date(localStorage.getItem('expireAt')) > new Date();
      const isLoggedIn = localStorage.getItem('token');
      return tokenNotExpired && isLoggedIn;
    },
  },
  methods: {
    // map all the actions from store, into the components
    ...mapActions('evaluations', [GET_EVALUATION_BY_ID]),
    ...mapActions('users', [GET_UPDATE_ME]),

    forceUpdate() {
      this.$forceUpdate();
    },

    openDropdown(e, index) {
      let dropdown = $('#headerAccordion' + index);
      dropdown.toggleClass('dropped');
      if (dropdown.hasClass('dropped')) {
        $(dropdown)
          .prev()
          .children('.open-dropdown')
          .removeClass('fa-angle-down');
        $(dropdown)
          .prev()
          .children('.open-dropdown')
          .addClass('fa-angle-up');
      } else {
        $(dropdown)
          .prev()
          .children('.open-dropdown')
          .addClass('fa-angle-down');
        $(dropdown)
          .prev()
          .children('.open-dropdown')
          .removeClass('fa-angle-up');
      }
      dropdown.slideToggle();
      e.preventDefault();
      e.stopPropagation();
    },

    setUserLocale(locale) {
      const tokenNotExpired =
        new Date(localStorage.getItem('expireAt')) > new Date();
      const isLoggedIn = localStorage.getItem('token') && tokenNotExpired;
      if (isLoggedIn) {
        this.$i18n.set(locale);
        this[GET_UPDATE_ME]({ lang: locale, _id: this.user._id });
      } else {
        this.$store.commit(`users/${SET_UPDATE_ME}`, {
          lang: locale,
        });
        this.$i18n.set(locale);
      }
      this.$forceUpdate();
    },

    getAssessor(assessorType, group) {
      if (group.assessors === undefined) return {};

      if (Array.isArray(group.assessors)) {
        return group.assessors.find(assessor => {
          if (assessorType === assessor.type) return true;
          return false;
        });
      } else {
        for (let assessorId in group.assessors) {
          if (assessorType === group.assessors[assessorId].type) {
            return group.assessors[assessorId];
          }
        }
      }
    },

    getFirstGroupByAssessorType(assessorType, groups) {
      if (Array.isArray(groups)) {
        return groups.filter(group => {
          return (
            group.assessor_list && group.assessor_list.includes(assessorType)
          );
        })[0];
      } else {
        let filteredGroups = {};
        for (let groupId in groups) {
          if (
            groups[groupId].assessor_list &&
            groups[groupId].assessor_list.includes(assessorType)
          ) {
            return groups[groupId];
          }
        }
      }
      return false;
    },

    getGroupsByAssessorType(assessorType, groups) {
      if (Array.isArray(groups)) {
        return groups.filter(group => {
          return (
            group.assessor_list && group.assessor_list.includes(assessorType)
          );
        });
      } else {
        let filteredGroups = {};
        for (let groupId in groups) {
          if (
            groups[groupId].assessor_list &&
            groups[groupId].assessor_list.includes(assessorType)
          ) {
            filteredGroups[groupId] = groups[groupId];
          }
        }
        return filteredGroups;
      }
    },
  },

  async mounted() {
    const lang = localStorage.getItem('lang')
    setTimeout(() => {
      if (this.$route.path.search(/assessors/) !== -1) {
        this.group = this.evaluation.groups[this.groupId];
      }
      if (!lang) {
        this.$i18n.set(
          (window.navigator.userLanguage || window.navigator.language).includes(
            'es'
          )
            ? 'es'
            : 'en'
        );
      } else {
        this.$i18n.set(lang);
      }
    }, 1000);
  },
};
</script>
